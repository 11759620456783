/**
 * (c) webXells GmbH
 *
 * This computer program is the sole property of webXells GmbH
 * (http://www.webXells.com) and is protected under the German Copyright Act
 * (paragraph 69a UrhG). All rights are reserved. Making copies,
 * duplicating, modifying, using or distributing this computer program
 * in any form, without prior written consent of webXells, is
 * prohibited. Violation of copyright is punishable under the
 * German Copyright Act (paragraph 106 UrhG). Removing this copyright
 * statement is also a violation.
 *
 * @author: Christian Lahrz-Beckmann
 * @since: 09.09.2019 - 14:24
 * @version: 0.0.1
 * @project: MIS-Templates
 * @file: index.js
 * @description:
 */

import "./ie.polyfill";
import $ from 'jquery';
import ErrorBox from "./errorbox";

window.$ = $;
window.ErrorBox = ErrorBox;

/*
 * alle Checkboxen aus- oder abwählen
 */
const $checkboxAlle = $('.hersteller-alle input[type=checkbox]');
const $checkboxHersteller = $('.hersteller__box input[type=checkbox]');
const $formHersteller = $('#mis-formular-1');

$checkboxAlle.on('change', function () {
    let isChecked = $(this).is(':checked');
    $checkboxHersteller.prop('checked', isChecked);
    $checkboxAlle.not($(this)).prop('checked', isChecked);
});

$formHersteller.on('submit', function (e) {
    // Prüfen ob mind. 1 Hersteller ausgewählt wurde
    const isChecked = $checkboxHersteller.filter(':checked');
    const errors = [];

    if (!isChecked.length || isChecked.length === 0) {
        errors.push('Bitte mindestens einen Aussteller auswählen');
    }

    if (errors.length) {
        ErrorBox.show(errors);

        e.preventDefault();
        return false;
    }
});
