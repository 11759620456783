import $ from 'jquery';

export default new class ErrorBox {

    constructor() {
        this.$error = $('.error');
        if (!this.$error) return;

        this.$errorClose = this.$error.find('.error__close');
        this.$errorList = this.$error.find('.error__list');
        this.initEventlistener();
    }

    initEventlistener() {
        if (!this.$error) return;

        this.$errorClose.on('click', (e) => {
            this.close();
        });
    }

    show(errorList) {
        if (!this.$error) return;

        this.$error.addClass('-active');

        if (!$.isArray(errorList)) return;

        let list = '';
        for (let i = 0; i < errorList.length; i++) {
            let errMsg = errorList[i];
            list += `<li>${errMsg}</li>`;
        }

        this.$errorList.html($(list));
    }

    close() {
        if (!this.$error) return;

        this.$error.removeClass('-active');
    }
}
